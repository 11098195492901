
.file-preview[data-v-2506e231] {
	display: flex;
	align-items: center;
	padding: 0.75rem;
	border: 1px solid transparent;
}
.file-preview+.file-preview[data-v-2506e231] {
	border-top-color: var(--border-color);
}
.file-preview[data-v-2506e231]:hover {
	background-color: var(--bg-color);
	border-color: var(--dark-border-color);
	border-radius: var(--border-radius);
}
.file-preview:hover+.file-preview[data-v-2506e231] {
	border-top-color: transparent;
}
.file-icon[data-v-2506e231] {
	border-radius: var(--border-radius);
	width: 2.625rem;
	height: 2.625rem;
	overflow: hidden;
	margin-right: var(--margin-md);
	flex-shrink: 0;
}
.file-icon img[data-v-2506e231] {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.file-icon .fallback[data-v-2506e231] {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid var(--border-color);
	border-radius: var(--border-radius);
}
.file-name[data-v-2506e231] {
	font-size: var(--text-base);
	font-weight: var(--text-bold);
	color: var(--text-color);
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
}
.file-size[data-v-2506e231] {
	font-size: var(--text-sm);
	color: var(--text-light);
}
.file-actions[data-v-2506e231] {
	width: 3rem;
	flex-shrink: 0;
	text-align: center;
}
.file-actions .btn[data-v-2506e231] {
	padding: var(--padding-xs);
	box-shadow: none;
}
.file-action-buttons[data-v-2506e231] {
	display: flex;
	justify-content: flex-end;
}
.muted[data-v-2506e231] {
	opacity: 0.5;
	transition: 0.3s;
}
.muted[data-v-2506e231]:hover {
	opacity: 1;
}
.frappe-checkbox[data-v-2506e231] {
	font-size: var(--text-sm);
	color: var(--text-light);
	display: flex;
	align-items: center;
	padding-top: 0.25rem;
}
.config-area[data-v-2506e231] {
	gap: 0.5rem;
}
.file-error[data-v-2506e231] {
	font-size: var(--text-sm);
	font-weight: var(--text-bold);
}
.file-detail[data-v-2506e231] {
	flex: 1;
}
.input-container[data-v-2506e231] {
	display: flex;
}
.file-name-input[data-v-2506e231] {
	width: 100%;
}
.ext[data-v-2506e231] {
	margin-left: 5px;
}
