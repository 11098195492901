
@import "cropperjs/dist/cropper.min.css";
img[data-v-b554d18d] {
	display: block;
	max-width: 100%;
	max-height: 600px;
}
.image-cropper-actions[data-v-b554d18d] {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: var(--margin-md);
}
