
.file-browser-list[data-v-aa89cfb5] {
	height: 300px;
	overflow: hidden;
	margin-top: 10px;
}
.file-filter[data-v-aa89cfb5] {
	padding: 3px;
}
.tree[data-v-aa89cfb5] {
	overflow: auto;
	height: 100%;
	padding-left: 0;
	padding-right: 0;
	padding-bottom: 4rem;
}
