
.file-upload-area[data-v-41693633] {
	min-height: 16rem;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px dashed var(--dark-border-color);
	border-radius: var(--border-radius);
	cursor: pointer;
	background-color: var(--bg-color);
}
.btn-file-upload[data-v-41693633] {
	background-color: transparent;
	border: none;
	box-shadow: none;
	font-size: var(--text-xs);
}
